import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import { Theme } from '@mui/system/createTheme';
import { SxProps } from '@mui/system/styleFunctionSx';
import { Link } from "gatsby";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";

export interface SmallPostCardProps {
	post: {
		title: string,
		description: string,
		tags: string[],
		banner?: IGatsbyImageData | undefined,
		date: string,
		slug: string,
	}
	sx: SxProps<Theme>
}

export const SmallPostCard: React.FC<SmallPostCardProps> = (props) => {
	return (
		<>
			<Link to={props.post.slug} style={{ textDecoration: 'none', color: 'inherit' }}>
				<Box
					display="grid"
					// Images (2nd column) are not shown on small screens
					gridTemplateColumns={props.post.banner && { xs: "1fr", sm: "2fr 1fr", md: "3fr 1fr" }}
					gap="2rem"
					alignItems="center"
					sx={props.sx}
				>
					<Box display="flex" flexDirection="column" gap="0.2rem" justifyContent="space-between">
						<Box display="flex" flexDirection="column" gap="0.5rem">
							<Typography variant='h5' fontWeight={"bold"}>{props.post.title}</Typography>
							<Typography variant="body2" color="text.secondary">{props.post.date}</Typography>
							<Box display="flex" gap="0.5rem" flexWrap="wrap" my="0.5rem">
								{props.post.tags.map((tag) => (
									<Chip
										key={tag}
										label={tag}
										variant="outlined"
										size="small"
										color="primary"
										sx={{ width: 'fit-content', cursor: "pointer" }}
									/>
								))}
							</Box>
							<Typography variant='body1' style={{ fontSize: "1rem" }}>{props.post.description}</Typography>
							<Typography variant='body1' style={{ fontSize: "1rem" }} fontWeight="bold">{`Read more >>`}</Typography>
						</Box>
					</Box>
					{/* Images (2nd column in the grid declared in the container above)
					are not shown on small screens */}
					{props.post.banner && (
						<Box display={{ xs: "none", sm: "block" }} maxHeight="150px">
							<GatsbyImage
								image={props.post.banner}
								style={{ height: "100%", width: "100%" }}
								objectFit="cover"
								alt={props.post.title}
								imgStyle={{ borderRadius: "1rem" }}
							/>
						</Box>
					)}
				</Box>
			</Link>
		</>
	)
}