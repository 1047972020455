import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import Typography from '@mui/material/Typography';
import { graphql, navigate } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import { SmallPostCard } from '../components/SmallPostCard';
import { GetAllMdxNodesForListingQuery } from '../graphqlTypes';


interface ListingPageProps {
	data: GetAllMdxNodesForListingQuery,
	pageContext: {
		pagination: {
			page: string[],
			pageCount: number,
			pageNum: number,
			nextPagePath: string,
			previousPagePath: string,
			pathPrefix: string,
		},
		pageTitle: string,
		pageImageData?: IGatsbyImageData,
		tag?: string,
	},
	location?: PageLocation, // Not available during SSR
}

const ListingPage: React.FC<ListingPageProps> = ({
	data: { allMdx },
	pageContext: {
		pagination,
		pageTitle,
		tag,
	}
}) => {
	const { page, pageCount, pageNum, pathPrefix } = pagination;

	const posts = page.map(id => {
		const edge = allMdx.edges.find(edge => edge.node.id === id)

		if (!edge) {
			throw new Error(`edge not found for page: ${id}`)
		}

		return edge
	});

	return (
		<Layout>
			<Seo
				title={pageTitle}
				description={tag ? `Articles about ${tag}` : "The Dev Tavern blog posts"}
			/>
			<Typography variant="h1" my="4rem">{pageTitle}</Typography>
			<Box
				className="ListingPage__listing_container"
				display="flex"
				flexDirection="column"
				gap="2rem"
				my="3rem"
			>
				{posts.map(({ node: post }) => (
					<SmallPostCard
						key={post.id}
						post={{
							title: post.frontmatter.title,
							description: post.frontmatter.description.short,
							tags: post.frontmatter.tags,
							banner: post.frontmatter.banner?.childImageSharp?.gatsbyImageData,
							slug: post.fields.defaultSlug,
							date: post.frontmatter.date,
						}}
						sx={{}}
					/>
				))}
			</Box>
			<Pagination
				count={pageCount}
				color="primary"
				page={pageNum}
				variant="outlined"
				onChange={(_, page) => navigate(`${pathPrefix}/${page}`)}
				sx={{ mx: "auto", width: "fit-content" }}
			/>
		</Layout >
	);
};

export default ListingPage;

export const pageQuery = graphql`
  query GetAllMdxNodesForListing {
    site {
		siteMetadata {
		  title
		  author {
			  name
		  }
		}
	}

    allMdx {
      edges {
        node {
          excerpt(pruneLength: 300)
          id
		  fields {
			  defaultSlug
		  }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            banner {
				childImageSharp {
				  gatsbyImageData
				}
			}
			description {
				short
				long
			}
            tags
          }
        }
      }
    }
  }
`;